.p-post-question-main > div {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

.p-post-question-main .m-pos-f {
  top: 0.9rem;
}

.p-post-question-main .question-title {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  padding: 0.3rem;
  border-bottom: 1px solid #ededed;
}

.p-post-question-main .question-title.step3 {
  padding: 0.4rem;
}

.p-post-question-main .question-title input {
  font-size: 0.3rem;
  line-height: 1.5;
  width: 100%;
}

.p-post-question-main .question-list {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  overflow: auto;
}

.p-post-question-main .question-list li {
  border-bottom: 1px solid #ededed;
  color: #666;
  font-size: 0.3rem;
  padding: 0.4rem;
}

.p-post-question-main .m-reles-body {
  height: auto;
  margin-bottom: 0;
}

.p-post-question-main .m-reles-body textarea {
  font-size: 0.32rem;
  line-height: 1.5;
  overflow: auto;
  margin-top: 0.35rem;
  padding: 0 0.3rem;
  background-color: transparent;
  outline: 0;
  border: 0;
  resize: none;
  width: 100%;
  max-height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}

.p-post-question-main .anonymity-switch {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #ededed;
}

.m-rich-box {
  font-size: 0.3rem;
  width: 100%;
  padding: 0.4rem;
  position: relative;
  overflow: auto;
}

.m-rich-box .placeholder {
  position: absolute;
  color: #ccc;
}

.m-editor {
  border: 0;
  outline: 0;
  line-height: 1.5;
  word-wrap: break-word;
  overflow-x: hidden;
  overflow-y: auto;
  _overflow-y: visible;
  -webkit-user-modify: read-write-plaintext-only;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  -webkit-user-select: text;
}

.m-topics {
  overflow-y: auto;
}

.m-topics.ml {
  margin-left: 0.15rem;
}

.m-topics li.m-topic {
  float: left;
  margin-left: 0.15rem;
  margin-top: 0.2rem;
  padding: 0.1rem 0.2rem;
  min-height: 0.5rem;
  line-height: 0.32rem;
  border-radius: 0.25rem;
  background-color: rgba(89, 182, 215, 0.2);
  color: #666;
  font-size: 0.26rem;
}

.m-topics li.m-topic > .m-svg-def {
  margin-left: 0.2rem;
  width: 0.32rem;
  height: 0.32rem;
  fill: #b2b2b2;
}

.m-topics div.m-topic {
  padding: 0.3rem;
}

.m-topics div.m-topic .m-topic-avatar {
  width: 0.8rem;
  height: 0.8rem;
  background-color: #ccc;
  margin-right: 0.3rem;
}

.m-topics div.m-topic h3 {
  font-size: 0.3rem;
  line-height: 1.5;
}

.m-topics div.m-topic p {
  width: 100%;
  margin-top: 0.09rem;
  overflow: hidden;
  font-size: 0.24rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #999;
}