.p-choose-category .m-cates[data-v-9a5aa240] {
  padding: 0.3rem;
}
.m-cates[data-v-9a5aa240] {
  margin-top: -0.3rem;
  margin-left: -0.3rem;
}
.m-cate[data-v-9a5aa240] {
  display: inline-block;
  padding: 0 0.1rem;
  margin-top: 0.3rem;
  margin-left: 0.3rem;
  width: -webkit-calc(1 / 4 * 100%  - 0.3rem);
  width: calc(1 / 4 * 100%  - 0.3rem);
  height: 0.6rem;
  line-height: 0.6rem;
  font-size: 0.28rem;
  background-color: #f4f5f5;
  border-radius: 0.08rem;
}
.m-cate span[data-v-9a5aa240] {
  overflow: hidden;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}
.p-post-news[data-v-55822b9c] {
  min-height: 100vh;
}
.p-post-news .m-poster-box[data-v-55822b9c] {
  position: relative;
  background: #f4f5f5;
  width: 3.8rem;
  height: 2.7rem;
  border: 1px solid #ededed;
  margin-bottom: 0.5rem;
}
.p-post-news .m-poster-box.loading img[data-v-55822b9c],
.p-post-news .m-poster-box.error img[data-v-55822b9c] {
  opacity: 0.3;
}
.p-post-news .m-poster-box.error[data-v-55822b9c]:after {
  content: "上传失败, 请重试";
  color: #f76c69;
  z-index: 9;
}
.p-post-news .m-poster[data-v-55822b9c] {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.p-post-news .m-poster-placeholder[data-v-55822b9c] {
  line-height: 1.5;
}
.p-post-news .step3[data-v-55822b9c] {
  padding: 0.8rem 0.3rem 0;
  color: #b3b3b3;
}
.p-post-news .step3 p[data-v-55822b9c] {
  margin-bottom: 0.8rem;
}
.p-post-news .p-post-news-main > div[data-v-55822b9c] {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}
.p-post-news .p-post-news-main .m-pos-f[data-v-55822b9c] {
  top: 0.9rem;
}
.p-post-news .m-post-news-title[data-v-55822b9c] {
  padding: 0.3rem 0.2rem;
}
.p-post-news .m-post-news-title input[data-v-55822b9c] {
  font-size: 0.3rem;
  height: 100%;
  line-height: 0.36rem;
}
.p-post-news .m-post-news-content[data-v-55822b9c] {
  overflow-y: auto;
  overflow-x: hidden;
}
.p-post-news .m-post-news-content textarea[data-v-55822b9c] {
  font-family: inherit;
  line-height: 0.36rem;
  font-size: 0.3rem;
  resize: none;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  padding: 0.3rem 0.2rem;
}
.p-post-news .m-post-news-row[data-v-55822b9c] {
  font-size: 0.3rem;
  padding: 0.4rem 0.3rem;
}
.p-post-news .m-post-news-row .m-entry-append[data-v-55822b9c] {
  margin-left: 0.1rem;
}
.p-post-news .m-post-news-row-label[data-v-55822b9c] {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -ms-flex-item-align: start;
  align-self: flex-start;
  width: 1.5rem;
}
.p-post-news .m-post-news-row input[data-v-55822b9c] {
  height: 100%;
  width: 100%;
  line-height: 0.3rem;
  font-size: 0.28rem;
}
.p-post-news .m-post-news-row .c-textarea-input[data-v-55822b9c] {
  text-align: right;
}
.p-post-news .placeholder[data-v-55822b9c] {
  color: #ccc;
}
.m-image-paid-option-box {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 120;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 0 0.5rem;
  width: 6.5rem;
  border-radius: 0.1rem;
  background-color: #fff;
}
.m-image-paid-option-head {
  padding: 0.4rem 0;
  color: #333;
  text-align: center;
  font-size: 0.32rem;
  border-bottom: 1px solid #ededed;
}
.m-image-paid-option-row {
  font-size: 0.32rem;
  padding: 0.4rem 0;
}
.m-image-paid-option-row .m-image-paid-option-radio,
.m-image-paid-option-row .m-pinned-amount-btn {
  padding: 0 0.2rem;
  height: 0.7rem;
  border-radius: 0.06rem;
  border: 1px solid #dedede;
  margin: 0 0.2rem;
}
.m-image-paid-option-row .m-image-paid-option-radio.active,
.m-image-paid-option-row .m-pinned-amount-btn.active {
  color: #59b6d7;
  border-color: #59b6d7;
}
.m-image-paid-option-label {
  font-size: 0.26rem;
  color: #999;
  margin-bottom: 0.3rem;
}
.image-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.image-wrap {
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  overflow: hidden;
  position: relative;
  margin: 0.5%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #ededed;
}
.image-wrap.img1 {
  -ms-flex-preferred-size: 99%;
  flex-basis: 99%;
}
.image-wrap.img3 {
  -ms-flex-preferred-size: 24%;
  flex-basis: 24%;
}
.image-wrap.loading:before {
  content: "";
}
.image-placeholder {
  padding-top: 100%;
}
.compose-image {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.compose-image.loading {
  opacity: 0.3;
}
.m-rpic-edit {
  z-index: 9;
  position: relative;
  background: transparent;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.m-rpic-edit-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.5rem;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 0.24rem;
  color: #fff;
  z-index: 1;
}
.m-rpic-edit-wrap .m-svg-def {
  width: 0.32rem;
  height: 0.32rem;
  margin-right: 0.1rem;
}
.m-rpic-close {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #000;
  opacity: 0.4;
  padding: 0;
  border: 0;
  outline: none;
  color: #fff;
  z-index: 3;
}
.m-rpic-close .m-style-svg {
  width: 0.42rem;
  height: 0.42rem;
}
.more-image {
  border: 1px dashed #ccc;
  color: #ccc;
}
.c-topic-selector[data-v-0d947b00] .jo-loadmore-main {
  margin-top: 0.9rem;
}
.c-topic-selector[data-v-0d947b00] .c-form-item {
  color: inherit;
}
.c-topic-selector[data-v-0d947b00] .c-form-item > label {
  white-space: nowrap;
}
.c-topic-selector .topic-select[data-v-0d947b00] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.3rem 0.2rem;
  border-bottom: 0.01rem solid #ededed;
}
.c-topic-selector .topic-select > li[data-v-0d947b00] {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 0.5rem;
  margin-left: 0.3rem;
  margin-top: 0.2rem;
  border-radius: 0.08rem;
}
.c-topic-selector .topic-select .topic-item[data-v-0d947b00] {
  position: relative;
  padding: 0 0.16rem;
  background-color: #cdcdcd;
  font-size: 0.24rem;
  color: #fff;
}
.c-topic-selector .topic-select .topic-item .m-svg-small[data-v-0d947b00] {
  position: absolute;
  top: -0.16rem;
  left: -0.16rem;
  width: 0.32rem;
  height: 0.32rem;
  padding: 0.06rem;
  background-color: #999;
  border-radius: 0.2rem;
}
.c-topic-selector .topic-select .topic-add[data-v-0d947b00] {
  padding: 0 0.3rem;
  border: 0.01rem solid #ededed;
}
.c-topic-selector .topic-select .topic-add .m-svg-small[data-v-0d947b00] {
  width: 0.3rem;
  height: 0.3rem;
  color: #999;
}
.c-topic-search-panel[data-v-74981a36] {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 10;
}
.c-topic-search-panel .title-bar[data-v-74981a36] {
  padding: 0.2rem 0.3rem;
  font-size: 0.26rem;
  background-color: #ededed;
  color: #999;
}
.c-topic-search-panel .search-list[data-v-74981a36] {
  border-top: 1px solid #ededed;
  margin-top: -1px;
}
.c-topic-search-panel .search-list .item[data-v-74981a36] {
  padding: 0.3rem;
  border-bottom: 1px solid #ededed;
}
.c-topic-search-panel .m-no-find[data-v-74981a36] {
  height: 8rem;
}
.c-topic-search-panel .primary {
  color: #59b6d7;
}
.c-topic-selector[data-v-0b1a5fa6] .c-form-item {
  color: inherit;
}
.c-topic-selector[data-v-0b1a5fa6] .c-form-item > label {
  white-space: nowrap;
}
.c-topic-selector .topic-select[data-v-0b1a5fa6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.3rem 0.2rem;
  border-bottom: 0.01rem solid #ededed;
}
.c-topic-selector .topic-select > li[data-v-0b1a5fa6] {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 0.5rem;
  margin-left: 0.3rem;
  margin-top: 0.2rem;
  border-radius: 0.08rem;
}
.c-topic-selector .topic-select .topic-item[data-v-0b1a5fa6] {
  position: relative;
  padding: 0 0.16rem;
  background-color: #cdcdcd;
  font-size: 0.24rem;
  color: #fff;
}
.c-topic-selector .topic-select .topic-item .m-svg-small[data-v-0b1a5fa6] {
  position: absolute;
  top: -0.16rem;
  left: -0.16rem;
  width: 0.32rem;
  height: 0.32rem;
  padding: 0.06rem;
  background-color: #999;
  border-radius: 0.2rem;
}
.c-topic-selector .topic-select .topic-add[data-v-0b1a5fa6] {
  padding: 0 0.3rem;
  border: 0.01rem solid #ededed;
}
.c-topic-selector .topic-select .topic-add .m-svg-small[data-v-0b1a5fa6] {
  width: 0.3rem;
  height: 0.3rem;
  color: #999;
}
[data-v-0b1a5fa6] .jo-loadmore-main {
  margin-top: 0.9rem;
}
.p-post-image[data-v-92525154] {
  background-color: #fff;
}
.p-post-image main .content-wrap[data-v-92525154] {
  padding: 0.2rem;
}
.p-post-image footer[data-v-92525154] {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 10;
}
.p-post-text[data-v-b72f32d0] {
  background-color: #fff;
}
.p-post-text main[data-v-b72f32d0] {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  padding-top: 0.9rem;
}
.p-post-text main .options[data-v-b72f32d0] {
  border-top: 0.01rem solid #ededed;
}
.p-post-text main .content-wrap[data-v-b72f32d0] {
  padding: 0.2rem;
}
.p-post-text main .unit[data-v-b72f32d0] {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}
.p-post-question-main > div[data-v-7240d37e] {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}
.p-post-question-main .m-pos-f[data-v-7240d37e] {
  top: 0.9rem;
}
.p-post-question-main .question-title[data-v-7240d37e] {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  padding: 0.3rem;
  border-bottom: 1px solid #ededed;
}
.p-post-question-main .question-title.step3[data-v-7240d37e] {
  padding: 0.4rem;
}
.p-post-question-main .question-title input[data-v-7240d37e] {
  font-size: 0.3rem;
  line-height: 1.5;
  width: 100%;
}
.p-post-question-main .question-list[data-v-7240d37e] {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  overflow: auto;
}
.p-post-question-main .question-list li[data-v-7240d37e] {
  border-bottom: 1px solid #ededed;
  color: #666;
  font-size: 0.3rem;
  padding: 0.4rem;
}
.p-post-question-main .m-reles-body[data-v-7240d37e] {
  height: auto;
  margin-bottom: 0;
}
.p-post-question-main .m-reles-body textarea[data-v-7240d37e] {
  font-size: 0.32rem;
  line-height: 1.5;
  overflow: auto;
  margin-top: 0.35rem;
  padding: 0 0.3rem;
  background-color: transparent;
  outline: 0;
  border: 0;
  resize: none;
  width: 100%;
  max-height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
}
.p-post-question-main .anonymity-switch[data-v-7240d37e] {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #ededed;
}
.m-rich-box[data-v-7240d37e] {
  font-size: 0.3rem;
  width: 100%;
  padding: 0.4rem;
  position: relative;
  overflow: auto;
}
.m-rich-box .placeholder[data-v-7240d37e] {
  position: absolute;
  color: #ccc;
}
.m-editor[data-v-7240d37e] {
  border: 0;
  outline: 0;
  line-height: 1.5;
  word-wrap: break-word;
  overflow-x: hidden;
  overflow-y: auto;
  _overflow-y: visible;
  -webkit-user-modify: read-write-plaintext-only;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  -webkit-user-select: text;
}
.m-topics[data-v-7240d37e] {
  overflow-y: auto;
}
.m-topics.ml[data-v-7240d37e] {
  margin-left: 0.15rem;
}
.m-topics li.m-topic[data-v-7240d37e] {
  float: left;
  margin-left: 0.15rem;
  margin-top: 0.2rem;
  padding: 0.1rem 0.2rem;
  min-height: 0.5rem;
  line-height: 0.32rem;
  border-radius: 0.25rem;
  background-color: rgba(89, 182, 215, 0.2);
  color: #666;
  font-size: 0.26rem;
}
.m-topics li.m-topic > .m-svg-def[data-v-7240d37e] {
  margin-left: 0.2rem;
  width: 0.32rem;
  height: 0.32rem;
  fill: #b2b2b2;
}
.m-topics div.m-topic[data-v-7240d37e] {
  padding: 0.3rem;
}
.m-topics div.m-topic .m-topic-avatar[data-v-7240d37e] {
  width: 0.8rem;
  height: 0.8rem;
  background-color: #ccc;
  margin-right: 0.3rem;
}
.m-topics div.m-topic h3[data-v-7240d37e] {
  font-size: 0.3rem;
  line-height: 1.5;
}
.m-topics div.m-topic p[data-v-7240d37e] {
  width: 100%;
  margin-top: 0.09rem;
  overflow: hidden;
  font-size: 0.24rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #999;
}

/*# sourceMappingURL=post.5d657f65.css.map*/