.c-topic-search-panel {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 10;
}

.c-topic-search-panel .title-bar {
  padding: 0.2rem 0.3rem;
  font-size: 0.26rem;
  background-color: #ededed;
  color: #999;
}

.c-topic-search-panel .search-list {
  border-top: 1px solid #ededed;
  margin-top: -1px;
}

.c-topic-search-panel .search-list .item {
  padding: 0.3rem;
  border-bottom: 1px solid #ededed;
}

.c-topic-search-panel .m-no-find {
  height: 8rem;
}