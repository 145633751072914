.p-post-text {
  background-color: #fff;
}

.p-post-text main {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  padding-top: 0.9rem;
}

.p-post-text main .options {
  border-top: 0.01rem solid #ededed;
}

.p-post-text main .content-wrap {
  padding: 0.2rem;
}

.p-post-text main .unit {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}