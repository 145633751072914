.c-topic-selector /deep/ .c-form-item {
  color: inherit;
}

.c-topic-selector /deep/ .c-form-item > label {
  white-space: nowrap;
}

.c-topic-selector .topic-select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.3rem 0.2rem;
  border-bottom: 0.01rem solid #ededed;
}

.c-topic-selector .topic-select > li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 0.5rem;
  margin-left: 0.3rem;
  margin-top: 0.2rem;
  border-radius: 0.08rem;
}

.c-topic-selector .topic-select .topic-item {
  position: relative;
  padding: 0 0.16rem;
  background-color: #cdcdcd;
  font-size: 0.24rem;
  color: #fff;
}

.c-topic-selector .topic-select .topic-item .m-svg-small {
  position: absolute;
  top: -0.16rem;
  left: -0.16rem;
  width: 0.32rem;
  height: 0.32rem;
  padding: 0.06rem;
  background-color: #999;
  border-radius: 0.2rem;
}

.c-topic-selector .topic-select .topic-add {
  padding: 0 0.3rem;
  border: 0.01rem solid #ededed;
}

.c-topic-selector .topic-select .topic-add .m-svg-small {
  width: 0.3rem;
  height: 0.3rem;
  color: #999;
}

/deep/ .jo-loadmore-main {
  margin-top: 0.9rem;
}